import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import responsive from '../../utils/responsive';
import PdfViewer from './PdfViewer';
import { Button } from '@mui/material';

const { mediaQuery, breakpoints, useMedia } = responsive;

// Docs: https://github.com/wojtekmaj/react-pdf

const PdfVisor = ({title }) => {

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageNumberInput, setPageNumberInput] = useState(1);
  const [disablePrev,setDisablePrev] = useState(true);
  const [disableNext,setDisableNext] = useState(true);

  const detectDevice = () => {
    var width = window.screen.availHeight ? window.screen.availWidth : window.screen.width;
    return width < 767;
  }

  var isMobile = detectDevice();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const url = urlParams.get("url");
debugger;

  var file = useMemo(
    () => ({
      url: url,
      httpHeaders: {"Allow-Control-Allow-Origin": "*"}
    }),
    [],
  );

  function onItemClick({ pageNumber: itemPageNumber }) {
    setPageNumber(itemPageNumber);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
    setPageNumberInput(1);
    setDisableNext(false)
  }

  function changePage(offset) {
    const pageNumberAux = pageNumber + offset;
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
    setDisablePrev(pageNumberAux <= 1)
    setDisableNext(isMobile ? pageNumberAux >= numPages: pageNumberAux > 1 && pageNumberAux === numPages - 1 ? pageNumberAux >= numPages - 1 : pageNumberAux >= numPages)

    setPageNumberInput(pageNumberAux)
  }

  function previousPage() {
    changePage(isMobile ? -1 : pageNumber === 1 ? 0 : -2);
  }

  function nextPage() {
    changePage(isMobile ? 1 : pageNumber === 1 ? 1 : 2);
  }

  function changePageInput(evt) {

    var page = parseInt(evt.target.value);
    setPageNumberInput(page)
   
  }

  function pushEnter(evt){

    if (evt.key === 'Enter') {
      var page = parseInt(evt.target.value);
      page = page % 2 == 0 || isMobile? page:page-1;
      setPageNumberInput(page)
      if(page && page >= 1 && page <= numPages){
        setPageNumber(page);
        setDisablePrev(page <= 1)
        setDisableNext(isMobile ? page >= numPages: page > 1 && page === numPages - 1 ? page >= numPages - 1 : page >= numPages)
      }
    }
  }

  function onFocus(evt){

    var page = parseInt(evt.target.value);
    page = page % 2 == 0 || isMobile? page:page-1;
    setPageNumberInput(page)
    if(page && page >= 1 && page <= numPages){
      setPageNumber(page);
      setDisablePrev(page <= 1)
      setDisableNext(isMobile ? page >= numPages: page > 1 && page === numPages - 1 ? page >= numPages - 1 : page >= numPages)
    }
    
  }

  return (
      <Wrapper>
        <WrapperViewer>
          <PdfViewer
            isMobile={isMobile}
            file={file}
            numPages={numPages}
            pageNumber={pageNumber || 1}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={console.error}
            onItemClick={onItemClick}
          />
          <DocumentControls>
            <DocumentControlsText>
              {"Página"} {pageNumber || (numPages ? 1 : '--')}{' '}
              {"de"} {numPages || '--'}
            </DocumentControlsText>
            <DocumentControlWrapper>
              <DocumentControlAction
                type='button'
                disabled={disablePrev}
                onClick={previousPage}>
                {"Anterior"}
              </DocumentControlAction>
              <InputPage value={pageNumberInput} onChange={changePageInput} onKeyDown={pushEnter} onBlur={onFocus} type="number"/>
              <DocumentControlAction
                type='button'
                disabled={disableNext}
                onClick={nextPage}>
                {"Siguiente"}
              </DocumentControlAction>
            </DocumentControlWrapper>
          </DocumentControls>
        </WrapperViewer>
      </Wrapper>
  );
};

const InputPage = styled.input`
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  text-align: center;
  line-height: 0.5em;
  height: 35px;
  margin-top: 5px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 0;
  height: 85vh;

  ${mediaQuery.TABLET`
    height: 90vh;
   `}
  ${mediaQuery.DESKTOP`
   height: 100vh;
   `}
`;

const H2 = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 10px;

  color: #616161;
`;

const WrapperViewer = styled.div`
  padding: 20px;
  background: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;

const DocumentControls = styled.div`
  z-index: 1000;
`;

const DocumentControlsText = styled.div`
  text-align: center;
  color: #fff;
  padding: 10px 0;
`;

const DocumentControlWrapper = styled.div`
  display: flex;
`;

const DocumentControlAction = styled(Button)`
  margin: 5px;
  color: white !important;
`;

const PublicationsWrapper = styled.div`
  padding: 20px 0;
`;

export default PdfVisor;
