import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import Copyright from '../../commons/Copyright';
import styled from "styled-components";
import { height } from '@mui/system';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useHistory } from "react-router-dom";
import { Container, Grid } from '@mui/material';
import HeaderPublic from '../../commons/HeaderPublic';
import { suscriptionTypePublicService as service } from '../../../services';
import { isNotLoginLogin,ssdoData } from '../../../utils/session';


export default function PagoConfirmado(props) {
    const { t, i18n } = useTranslation();
    const [data,setData] = useState([])
    let history = useHistory()


    const clickContinue = (evt) => {
        if (isNotLoginLogin()) {

        } else {
            var sso = ssdoData();
            var url = process.env.REACT_APP_PROTECMEDIA+"/user-portlet/refreshuserentitlements?ssodata="+sso;
            console.log(url);
            window.location.href=url;
        }
   

    }



    return (<Main>
            <Container>
            <HeaderPublic style={{margin: "1em 0"}}/>
            <CardLongin>

                <p>Gracias !!! Su pado ha sido confirmado</p>
                <DivForm>
                    <Typography variant="h2" color="textSecondary" align="center">Gracias!! Su pago ha sido confirmado</Typography>
                    <ButtonForm variant="contained" color="primary" onClick={clickContinue}>Continuar</ButtonForm><br/>
                </DivForm>
                
                <Copyright />
            </CardLongin>
            </Container>
            </Main>
            
      );

}

const CardLongin = styled(Card)`
    padding: 1em 0.7em;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Main = styled.div`
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
    width: 100%;
    height: 100vh;
    display: flex;
`;


const DivForm =  styled.div`
    display: flex;
    flex-direction:column;
    width: 400px;
    max-width: 90%;
    row-gap: 10px;
`;

const ButtonForm = styled(Button)`
    margin-top: 20px;
    margin-bottom: 20px;
`;
