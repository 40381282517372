import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import Copyright from '../../commons/Copyright';
import styled from "styled-components";
import { height } from '@mui/system';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useHistory } from "react-router-dom";
import { Container, Grid } from '@mui/material';
import HeaderPublic from '../../commons/HeaderPublic';
import { suscriptionTypePublicService as service } from '../../../services';
import { isNotLoginLogin } from '../../../utils/session';


export default function Suscripciones(props) {
    const { t, i18n } = useTranslation();
    const [data,setData] = useState([])
    let history = useHistory()


    
    useEffect(()=>{
            service.all((data,error)=>{
                if(data && data.data){
                    setData(data.data);
                }
            });
    },[]);

    const getDur = (sus) => {
        if(sus["unit"] == 'HOUR') {
            if (sus["ammount"] == 24) {
                return "dia";
            } else {
                return sus["ammount"] + "h";
            }
        } else if(sus["unit"] == 'DAY') {
            if (sus["ammount"] == 365) {
                return "año";
            } else {
                return sus["ammount"] + "días";
            }
        }  else if(sus["unit"] == 'MIN') {
            if (sus["ammount"] == 60) {
                return "hora";
            } else {
                return sus["ammount"] + "min";
            }
        } 
    }

    const clickSus = (sus) => {

        if(isNotLoginLogin()){
            history.push("/acceso?suscription="+sus.id);
        } else {
            history.push("/suscripciones/data/"+sus.id);
        }

    }

    return (<Main>
            <Container>
            <HeaderPublic style={{margin: "1em 0"}}/>
            <CardLongin>

                <Grid container>
                  { data.map((sus)=> (
                    <Grid item sm={3} xs={12} style={{padding: "0.5em"}}>
                        <DivSus>
                            <h3>{sus['name']}</h3>
                            <p className={"price"}>{sus['price']}</p>
                            <p>€/{getDur(sus)}</p>
                            <button onClick={()=>clickSus(sus)}>Suscribirme</button>
                        </DivSus>
                    </Grid>
                  ))

                  }

                </Grid>
                
                <Copyright />
            </CardLongin>
            </Container>
            </Main>
            
      );

}

const CardLongin = styled(Card)`
    padding: 1em 0.7em;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Main = styled.div`
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
    width: 100%;
    height: 100vh;
    display: flex;
`;

const DivSus = styled.div`
   background: rgb(1, 81, 167);
   color: white;
   display: flex;
   flex-direction: column;
   padding: 1em;
   height: 100%;
   & h3 {
    text-transform: uppercase;
    margin-bottom: 18px;
    font-size: 0.8em;
    font-weight: 500;
    width: 100px;
    display: flex;
    align-self: center;
    flex-basis: 40%;
   }

   & .price {
    font-size: 2em;
   }

   & button {
    background: white;
    padding: 1em;
    border: none;
    border-radius: 6px;
    color: rgb(1, 81, 167);
   }
`;

