import CrudTable from "../../crud/CrudTable"
import {adsService as service, positionAdsService} from "../../../services"

const initFilter =  {
    text : null,
};

export default function Ads() {

  const createEstructureFilter =  [
     {
       type:"input",
       name: "title",
       col: 4
     },
      {
      type:"autocomplete",
      name: "idPosition",
      service: positionAdsService.combo,
      col: 4
      },
      {
        type:"input",
        name: "visible",
        inputtype:"date",
        col: 4
      },

  ];




  return (
    <CrudTable
          service={service}
          urledit={"/ads/edit/"}
          urlnew={"/ads/new/"}
          filter={initFilter}
          i18n={"ads"}
          fieldstable={["image","title","startDate","endDate","position.name","view","click"]}
          estructureForm={createEstructureFilter}
          notexport={true} />
  );
}

