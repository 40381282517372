import BaseService from './_base.service';

const URL = "/suscriptiontype";

export const suscriptionTypePublicService = {
    all,
    combo,
    one,
};

function all(callback) {
    return BaseService.get(URL+"/all/", callback)
}

function combo(callback) {
    return BaseService.get(URL+"/combo/", callback, true)
}
function one(id,callback) {
    return BaseService.get(URL + "/one/"+id, callback);
}
