import CrudTable from "../../crud/CrudTable"
import {positionAdsService as service} from "../../../services"

const initFilter =  {
    text : null,
};

export default function PosicionAds() {

  const createEstructureFilter =  [
     {
       type:"input",
       name: "text",
       col: 4
      }
  ];




  return (
    <CrudTable
          service={service}
          urledit={"/posicionads/edit/"}
          urlnew={"/posicionads/new/"}
          filter={initFilter}
          i18n={"posicionads"}
          fieldstable={["code","name"]}
          fieldId={"code"}
          estructureForm={createEstructureFilter}
          notexport={true} />
  );
}
