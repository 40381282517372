import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import Copyright from '../../commons/Copyright';
import styled from "styled-components";
import { height } from '@mui/system';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { userService as service, userService } from '../../../services';
import { useHistory } from "react-router-dom";
import { Container } from '@mui/material';
import HeaderPublic from '../../commons/HeaderPublic';
import Form from '../../form/Form';

const initElement = {
    email:"",
    pass: "",
    name: "",
    lastname: "",
    isClient:true,
    street:"",
    phone: "",
    number: "",
    door: "",
    floor:"",
    stairs: "",
    city: "",
    state: "",
    country: "",
    postalcode: "",
    repass: "",
    rol: {code:"CLIENT",name:"CLIENT"},
    enable: true,
 };



export default function Register(props) {
    const { t, i18n } = useTranslation();
    const [data,setData] = useState({...initElement})
    const [haveError,setHaveError] = useState(false)
    const [load,setLoad] = useState(false)
    const [mess,setMess] = useState("")
    let history = useHistory()




  const estructureform =  [
    {
     type:"title",
     name: "personaldata",
     col: 12
    },
    {
     type:"input",
     name: "name",
     col: 4
    },
    {
     type:"input",
     name: "lastname",
     col: 4
    },
    {
     type:"input",
     name: "email",
     inputtype:"email",
     col: 4
    },
    {
     type:"input",
     name: "phone",
     col: 4
    },
    {
        type:"title",
        name: "titledir",
        col: 12
    },
    {
     type:"input",
     name: "country",
     col: 4
    },
    {
     type:"input",
     name: "state",
     col: 4
    },
    {
     type:"input",
     name: "city",
     col: 4
    },  
    {
     type:"input",
     name: "postalcode",
     col: 4
    },
    {
     type:"input",
     name: "street",
     col: 4
    },
    {
     type:"input",
     name: "number",
     col: 2
    },   
    {
     type:"input",
     name: "floor",
     col: 2
    },   
    {
     type:"input",
     name: "door",
     col: 2
    },   
    {
     type:"input",
     name: "stairs",
     col: 2
    },  
    {
        type:"title",
        name: "titlepasss",
        col: 12
    }, 
    {
     type:"input",
     name: "pass",
     col: 4,
     inputtype: "password"
    },   
    {
     type:"input",
     name: "repass",
     col: 4,
     inputtype: "password"
    }, 
    {
     type:"renderCheckBoxLink",
     name: "acceptpolicy",
     link: "https://www.sweetpress.com/politica-de-privacidad",
     col: 12,
    },
];

    const onSubmit = (evt) => {
        evt.preventDefault();
        userService.login(data, (data, error) => {
            if (data) {
                localStorage.setItem('token', data.token);
                localStorage.setItem('user', JSON.stringify(data.user));
                if (props.client || data.user.isClient) {
               //     var url = process.env.REACT_APP_PROTECMEDIA+"/user-portlet/refreshuserentitlements?"+(redirect? "redirect="+encodeURI(redirect)+"&":"")+"ssodata="+data.user.ssodata;
                //    window.location.href=url;
                } else {
                  history.push("/dashboard");
                }
               
             } else {
               setHaveError(true);
             }
        });
        return false;
    } 

    const save = (object) => {
        if(isValid(object)) {
            setLoad(true);
            userService.register(object, (data,error) => {
                setLoad(false);
                if (data && data.data) {
                        login(object)
                } else {
                    setMess("Ha ocurrido un error. Vuelva a internarlo mas tarde");
                }
            });
        }
       
    }

    const login = (object) => {
        const loginData = {
            email:object["email"],
            pass: object["pass"]
        }
        userService.login(loginData, (data, error) => {
            if (data) {
                localStorage.setItem('token', data.token);
                localStorage.setItem('user', JSON.stringify(data.user));
                history.push("/suscripciones");
             } else {
               setMess("Ha ocurrido un error. Vuelva a internarlo mas tarde");
             }
        });

    }

    const isValid = (object) => {

        if(!object["name"] && object["name"] != null){
            setMess("Debe indicar el nombre");
            return false;
        } else if(!object["lastname"] && object["lastname"] != null){
            setMess("Debe indicar el apellido");
            return false;
        } else if(!object["email"] && object["email"] != null){
            setMess("Debe indicar el email");
            return false;
        } else if(!object["pass"] && object["pass"] != null){
            setMess("Debe indicar la contraseña");
            return false;
        } else if(!object["repass"] && object["repass"] != null){
            setMess("Las contraseñas no coinciden");
            return false;
        } else if(object["repass"] != object["pass"] ){
            setMess("Las contraseñas no coinciden");
            return false;
        }else if(!object["acceptpolicy"]){
            setMess("Debe aceptar nuestra politica de privacidad");
            return false;
        } else {
            return true;
        }

    }

    const goBack = (object) => {
        history.goBack();
    }

    return (<Main>
            <Container>
            <HeaderPublic style={{margin: "1em 0"}}/>
            <CardLongin>
                <Form t={t} i18n={"registro"}  objectform={data} fields={estructureform}  searchFilter={save} cleanFilter={goBack} labelsave={"Registrase"} labelcancel={"Volver"} reload={false} haveMoreActions={false} load={load}/>
                {mess && mess != "" && <p style={{background: "rgba(255,0,0,0.6)", padding: "1em", borderRadius:"6px", margin: "1em 0", textAlign: "center", color:"darkred", border:"1px solid darkred", width:"100%"}}>{mess}</p>}
                <Copyright />
            </CardLongin>
            </Container>
            </Main>
            
      );

}

const CardLongin = styled(Card)`
    padding: 1em;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
`;


const Main = styled.div`
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
    width: 100%;
    height: 100vh;
    display: flex;
`;
