import React, {useState} from 'react';
import { useParams,useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Copyright from '../../commons/Copyright';
import styled from "styled-components";
import { height } from '@mui/system';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { userService as service, userService } from '../../../services';
import { CircularProgress, Container } from '@mui/material';
import HeaderPublic from '../../commons/HeaderPublic';

const loginData = {
    email:""
}

export default function Recovery() {
    const { t, i18n } = useTranslation();
    const [data,setData] = useState({...loginData})
    const [haveError,setHaveError] = useState(false);
    const [isOk,setIsOk] = useState(false);
    let history = useHistory()
    let { client } = useParams<any>();
    const [isClient, setIsClient] = useState(client && client == 'client'?true:false);
    const [isLoading, setIsLoading] = useState(false);

    const onChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        setData(currentValueform => ({...currentValueform, [name]: value }))
    }

    const onSubmit = (evt) => {
        evt.preventDefault();
        setIsLoading(true);
        userService.recovery(data, (data, error) => {
            setIsLoading(false);
            if (data) {
                setIsOk(true);
             } else {
               setHaveError(true);
             }
        });
        return false;
    } 


    return (<Main>
            <Container>
            <HeaderPublic style={{margin: "1em 0"}}/>
            { !isOk && <CardLongin>
                <form method='post' onSubmit={onSubmit}>
                <DivForm>
                    <Typography variant="body2" color="textSecondary" align="center">Recuperar contraseña</Typography>
                    <Typography variant="body2" color="textSecondary" align="center">Introduzca su email y le enviaremos un enlace para restablecer la contraseña de su cuenta.</Typography>
                    <TextFieldForm value={data.email} id="email" name="email" required variant="standard" label={t('login.email')} onChange={onChange} type="email"/><br/>
                    {haveError && <Typography variant="body2" color="error" align="center">{t('login.error')}</Typography> }
                    {!isLoading && <><ButtonForm variant="contained" color="primary" type="submit">Restablecer contraseña</ButtonForm><br/></>}
                    {isLoading && <p style={{textAlign: "center"}}><CircularProgress /></p>}
                    <Button variant='text' onClick={()=>history.goBack()} color="primary" style={{textTransform: "capitalize"}}>Volver</Button><br/>
                </DivForm>
                </form>
                <Copyright />
            </CardLongin> }
            {isOk && <CardLongin>
                <p>Se ha enviado una nueva contraseña a tu email</p>
                <Button variant='text' onClick={()=>history.goBack()} color="primary" style={{textTransform: "capitalize"}}>Volver</Button><br/>
            </CardLongin>}
            </Container>
            </Main>
            
      );

}

const CardLongin = styled(Card)`
    padding: 1em;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const TextFieldForm = styled(TextField)`
    margin-bottom:20px;
`;
const ButtonForm = styled(Button)`
    margin-top: 20px;
    margin-bottom: 20px;
`;

const Main = styled.div`
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
    width: 100%;
    height: 100vh;
    display: flex;
`;

const ImgLogo = styled.img`
    width: 200px;
    max-width: 40%;
`;

const DivForm =  styled.div`
    display: flex;
    flex-direction:column;
    width: 400px;
    max-width: 90%;
    row-gap: 10px;
`;