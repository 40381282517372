import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import CrudForm from "../../crud/CrudForm"
import {suscriptionTypeService as service} from "../../../services"
import { useTranslation } from 'react-i18next';

const initElement = {
  name:"",
  description: "",
  ammount: null,
  unit: null,
  price: 0.0,
  };


  type FormAdministratorParms = {
    id: string;
  };
  
export default function FormSuscriptionType() {
  const [objectform, setObjectform] = React.useState({...initElement});
  let { id } = useParams<FormAdministratorParms>();
  const [idRow, setIdrow] = useState(id?Number(id):0);
  const { t, i18n } = useTranslation();


  const estructureform =  [
          {
           type:"input",
           name: "name",
           col: 4,
          },
          {
           type:"input",
           name: "ammount",
           inputtype:"number",
           col: 4
          },
          {
           type:"autocomplete",
           name: "unit",
           options:[{key:"DAY",name:"Días"},{key:"HOUR",name:"Horas"},{key:"MIN",name:"Minutos"}],
           col: 4,
          },
          {
           type:"input",
           name: "price",
           inputtype:"number",
           step:0.01,
           col: 4
          },
          {
            type:"editor",
            name:"description",
            col:12
          }
    ];

  const recoveryElement = function(objectedit,element){


    objectedit['code']=element.code?element.code:"";
    objectedit['name']=element.name?element.name:"";

    return objectedit;
  }




  const isValid = function(object){
    if(!object['name']){
      return t("suscriptiontype.error.name");
    }
    if(!object['price']){
      return t("suscriptiontype.error.price");
    }
    if(!object['ammount']){
      return t("suscriptiontype.error.ammount");
    }
    if(!object['unit']){
      return t("suscriptiontype.error.unit");
    }

    return null;
}

  return (
    <><CrudForm
        objectform = {{...initElement}}
        estructureform = {estructureform}
        i18n={"suscriptiontype"}
        urledit={"/suscriptiontype/edit/"}
        urlCancel={"/suscriptiontype"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
        fieldId={"code"}
      />
    </>

  );
}
