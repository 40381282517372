import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import CrudForm from "../../crud/CrudForm"
import {ordersService as service} from "../../../services"
import { useTranslation } from 'react-i18next';

const initElement = {
  user:{id:null,name:""},
  suscriptionType: {id:null,name:""},
  createDate: null,
  state: null,
  prize: null,
  prizeNum: 0.0,
  needInvoice:false,
  nameInvoice: null,
  streetInvoice: null,
  numberInvoice: null,
  floorInvoice: null,
  townInvoice: null,
  stateInvoice: null,
  postalCodeInvoice: null,
  countryInvoice: null,
  taxIdentificationNumberInvoice: null,
  emailSendInvoice: null,

  };


  type FormAdministratorParms = {
    id: string;
  };
  
export default function FormOrders() {
  const [objectform, setObjectform] = React.useState({...initElement});
  let { id } = useParams<FormAdministratorParms>();
  const [idRow, setIdrow] = useState(id?Number(id):0);
  const { t, i18n } = useTranslation();


  const estructureform =  [
  
          {
          type:"input",
          name: "createDate",
          readonly:true,
          inputtype:"date",
          col: 4,
          },
          {
           type:"input",
           name: "user.name",
           readonly:true,
           col: 4,
          },
          {
           type:"input",
           name: "user.email",
           col: 4,
           readonly:true,
          },
          {
           type:"input",
           name: "suscriptionType.name",
           readonly:true,
           col: 4
          },
          {
           type:"input",
           name: "state",
           col: 4,
           readonly:true,
          },
          {
           type:"input",
           name: "prizeNum",
           readonly:true,
           col: 4
          },
          {
            type:"title",
            name:"datosfactura",
           readonly:true,
            col:12
          },
          {
            type:"input",
            name: "nameInvoice",
            readonly:true,
            col: 4
          },
          {
            type:"input",
            name: "emailSendInvoice",
            readonly:true,
            col: 4
          },
          {
            type:"input",
            name: "taxIdentificationNumberInvoice",
            readonly:true,
            col: 4
          },
          {
            type:"input",
            name: "streetInvoice",
            readonly:true,
            col: 4
          },
          {
           type:"input",
           name: "numberInvoice",
           readonly:true,
           col: 2
          },
          {
             type:"input",
             name: "door",
             readonly:true,
             col: 2
          },
          {
             type:"input",
             name: "floorInvoice",
             readonly:true,
             col: 2
          },
           {
              type:"input",
              name: "townInvoice",
              readonly:true,
              col: 4
           },
           {
              type:"input",
              name: "stateInvoice",
              readonly:true,
              col: 4
           },
           {
              type:"input",
              name: "countryInvoice",
              readonly:true,
              col: 4
           },
           {
              type:"input",
              name: "postalCodeInvoice",
              readonly:true,
              col: 4
           },
    ];

  const recoveryElement = function(objectedit,element){


    objectedit = {...objectedit,element};
    objectedit['prizeNum']  = objectedit['prizeNum']? objectedit['prizeNum'] / 100 : 0;

    return objectedit;
  }




  const isValid = function(object){
    if(!object['name']){
      return t("suscriptiontype.error.name");
    }
    if(!object['price']){
      return t("suscriptiontype.error.price");
    }
    if(!object['ammount']){
      return t("suscriptiontype.error.ammount");
    }
    if(!object['unit']){
      return t("suscriptiontype.error.unit");
    }

    return null;
}

  return (
    <><CrudForm
        objectform = {{...initElement}}
        estructureform = {estructureform}
        i18n={"orders"}
        urledit={"/orders/edit/"}
        urlCancel={"/orders"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
        fieldId={"code"}
        onlyRead={true}
      />
    </>

  );
}
