import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import jwtDecode from 'jwt-decode';
import {isNotLoginLogin, isClient} from '../../utils/session'

const LoginRoute = ({ component: Component,client, ...rest }) => {


  const isLoggedNotIn = isNotLoginLogin();

  return (
    <Route
    {...rest}
    render={props =>
      !isLoggedNotIn && !client? (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      ) : (
        <Component {...props} client={client}/>
      )
    }
  />
  )
}

export default LoginRoute
