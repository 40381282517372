import CrudTable from "../../crud/CrudTable"
import {userService as service} from "../../../services"
import CrudEmpty from "../../crud/CrudEmpty";


export default function Dashboard() {




  return (
    <CrudEmpty 
        i18n={"dashboard"}
        goback={false} />
  );
}
