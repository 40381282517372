import CrudTable from "../../crud/CrudTable"
import {suscriptionTypeService as service} from "../../../services"

const initFilter =  {
    text : null,
};

export default function SuscriptionType() {

  const createEstructureFilter =  [
     {
       type:"input",
       name: "text",
       col: 4
      }
  ];




  return (
    <CrudTable
          service={service}
          urledit={"/suscriptiontype/edit/"}
          urlnew={"/suscriptiontype/new/"}
          filter={initFilter}
          i18n={"suscriptiontype"}
          fieldstable={["name","ammount|unit","price"]}
          fieldId={"id"}
          labels={{"ammount|unit":"Duración"}}
          estructureForm={createEstructureFilter}
          notexport={true} />
  );
}
