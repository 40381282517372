import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import MenuElement from './MenuItem';
import MenuParent from './MenuParent';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PictureInPictureIcon from '@mui/icons-material/PictureInPicture';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import InventoryIcon from '@mui/icons-material/Inventory';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const closedMixinXs = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `0px`,
  });

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
      '& .MuiListItemText-root' : {
        display: 'none'
      },
      '& .MuiListItem-root' : {
        paddingLeft: '8px !important',
      }, 
      '& .MuiListItemIcon-root': {
        minWidth: '26px'
      }
      

    }),
    [theme.breakpoints.down('sm')]: {
        ...(!open && {
            ...closedMixinXs(theme),
            '& .MuiDrawer-paper': closedMixinXs(theme),
            '& .MuiListItemText-root' : {
              display: 'none'
            }
          }),
    }
  }),
);

export default function Menu(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(props.open);
  const [openProduct, setOpenProduct] = React.useState(false);
  const [openSales, setOpenSales] = React.useState(false);
  const [openBuys, setOpenBuys] = React.useState(false);
  const [openConfig, setOpenConfig] = React.useState(false);
  const [openData, setOpenData] = React.useState(false);
  const [openCash, setOpenCash] = React.useState(false);
  const [openPay, setOpenPay] = React.useState(false);
  const [openReport, setOpenReport] = React.useState(false);
  const [openContable, setOpenContable] = React.useState(false);
  const [openEmpl, setOpenEmpl] = React.useState(false);

  
  
  const t = props.t;

  const closeAll = (id) => {
    if (id != 1){
      setOpenProduct(false);
    } else {
      setOpenProduct(!openProduct);
    }
    if (id != 2){
      setOpenConfig(false);
    } else {
      setOpenConfig(!openConfig);
    }
    if (id != 3){
      setOpenData(false);
    } else {
      setOpenData(!openData);
    }
    if (id != 4){
      setOpenSales(false);
    } else {
      setOpenSales(!openSales);
    }
    if (id != 5){
      setOpenBuys(false);
    } else {
      setOpenBuys(!openBuys);
    }
    if (id != 6){
      setOpenCash(false);
    } else {
      setOpenCash(!openCash);
    }
    if (id != 7){
      setOpenPay(false);
    } else {
      setOpenPay(!openPay);
    }

    if (id != 8){
      setOpenReport(false);
    } else {
      setOpenReport(!openReport);
    }

    if (id != 9){
      setOpenContable(false);
    } else {
      setOpenContable(!openContable);
    }

    if (id != 10){
      setOpenEmpl(false);
    } else {
      setOpenEmpl(!openEmpl);
    }
    
    
  }

  React.useEffect(() => {
    setOpen(props.open)
 }, [props.open]);


 /**
  * 
  *  <MenuParent icon={<ContactsIcon />} text={t("menu.comercial")} open={openEmpl} num={10} close={closeAll}>
            <MenuElement url={"/comercial"} icon={<ContactsIcon />} text={t("menu.comercial")}  inside={true}   />
            <MenuElement url={"/hollyday"} icon={<LiquorIcon />} text={t("menu.hollyday")} />
            <MenuElement url={"/entryemployee"} icon={<DoorbellIcon />} text={t("menu.entryemployee")} />
        </MenuParent>
  */

  return (
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={props.handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <MenuElement url={"/dashboard"} icon={<DashboardIcon />} text={t("menu.dashboard")} />
        <MenuElement url={"/administrator"} icon={<ManageAccountsIcon />} text={t("menu.users")} />
        <MenuElement url={"/users"} icon={<PersonIcon />} text={t("menu.clients")} />
        <MenuElement url={"/posicionads"} icon={<PictureInPictureIcon />} text={t("menu.positionads")} />
        <MenuElement url={"/ads"} icon={<InsertPhotoIcon />} text={t("menu.ads")} />
        <MenuElement url={"/suscriptiontype"} icon={<InventoryIcon />} text={"Tipos de suscripción"} />
        <MenuElement url={"/orders"} icon={<ShoppingCartIcon />} text={"Compras"} />
        
      </Drawer>
  );
}
