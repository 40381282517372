import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import CrudForm from "../../crud/CrudForm"
import {adsService as service, positionAdsService} from "../../../services"
import { useTranslation } from 'react-i18next';

const initElement = {
      title:"",
      description: "",
      url:"",
      image: null,
      imageMovil:null,
      html:null,
      isImage:true,
      startDate:null,
      endDate:null,
      position:{code:null,name:null},
      openSamePage:false,
      view:0,
      click:0,
  };



  type FormAdministratorParms = {
    id: string;
  };
  
export default function FormAds() {
  const [objectform, setObjectform] = React.useState({...initElement});
  let { id } = useParams<FormAdministratorParms>();
  const [idRow, setIdrow] = useState(id?Number(id):0);
  const { t, i18n } = useTranslation();


  const estructureform =  [
          {
           type:"input",
           name: "title",
           col: 4,
          },
          {
           type:"input",
           name: "description",
           col: 8
          }, 
          {
            type:"input",
            name: "url",
            col: 4
          },
          {
            type:"input",
            name: "startDate",
            inputtype:"date",
            col: 4
          },
          {
            type:"input",
            name: "endDate",
            inputtype:"date",
            col: 4
          },
          {
            type:"autocomplete",
            name:"position.code",
            service: positionAdsService.combo,
            col:4
          },
          {
            type:"image",
            name: "image",
            col: 2
          },
          {
            type:"image",
            name: "imageMovil",
            col: 2
          },
          {
            type:"checkbox",
            name: "isImage",
            col: 2
          },
          {
            type:"checkbox",
            name: "openSamePage",
            col: 2
          },
          {
            type:"textarea",
            name: "html",
            col: 12
          },
    ];

  const recoveryElement = function(objectedit,element){


    objectedit = {...objectedit, ...element};

    return objectedit;
  }




  const isValid = function(object){
    if(!object['name']){
      //return t("posicionads.error.name");
    }
    if(!object['code']){
      //return t("posicionads.error.code");
    }

    return null;
}

  return (
    <><CrudForm
        objectform = {{...initElement}}
        estructureform = {estructureform}
        i18n={"ads"}
        urledit={"/ads/edit/"}
        urlCancel={"/ads"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
      />
    </>

  );
}
