import React, { useState, useEffect } from 'react';
import PdfVisor from '../../visor/PdfVisor';
  
export default function Visor() {



  return (
    <PdfVisor title="Leer la revista"/>

  );
}
