import CrudTable from "../../crud/CrudTable"
import {ordersService as service} from "../../../services"
import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';

const initFilter =  {
  email:null,
  state:null,
  text:null,
  to:null,
  from:null
};

export default function Orders() {

  const [filter,setFilter] = useState({...initFilter});
  const [exportNum,setExportNum] = useState(0);

  const createEstructureFilter =  [
     {
       type:"input",
       name: "email",
       inputtype:"email",
       col: 3
      },
      {
        type:"autocomplete",
        name: "state",
        options:[{key:"PAGADO",name:"PAGADO"},{key:"PENDIENTE DE PAGO",name:"PENDIENTE DE PAGO"}],
        col: 3
       },
       {
         type:"input",
         name: "from",
         inputtype:"date",
         col: 3
        },
        {
          type:"input",
          name: "to",
          inputtype:"date",
          col: 3
         },
  ];




  const paintCell = {
    prizeNum: (value,object) => {
      if (value) {
        return <>{value / 100.0}</>
      } else {
        return <></>
      }
    }
  }

  const exportExcel = () => {
      setExportNum(currentExporNum => (currentExporNum+1));
  }

  const othersPanel = () => {
    
    return <Grid container>
            <Grid item sm={6} xs={12} style={{textAlign:'left', display:"flex", flexDirection:"row"}}></Grid> 
              <Grid item sm={6} xs={12} style={{textAlign:"right"}}>
                <Button variant="contained" color="primary" style={{margin:'10px', fontSize: "0.8em"}} onClick={exportExcel}> Exportar </Button>
            </Grid>
          </Grid>;
  }

  useEffect(()=>{
      if(exportNum > 0){
          service.allFilterExcel(filter,(data,error)=>{

          });
      }
  },[exportNum,filter])

  return (
    <CrudTable
          service={service}
          urledit={"/orders/edit/"}
          hiddenButton={true}
          filter={filter}
          i18n={"orders"}
          fieldstable={["createDate","user.name|user.email","suscriptionType.name","state", "prizeNum", "needInvoice"]}
          fieldId={"id"}
          labels={{"user.name|user.email":"Cliente"}}
          estructureForm={createEstructureFilter}
          deleteaction={false}
          notexport={true}
          defaultValues={{"state":"PENDIENTE DE PAGO"}}
          haveother={true}
          othersPanel={othersPanel()}
          paintCell={paintCell}
          iconEditAction={<VisibilityIcon />} />
  );
}

