import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import CrudForm from '../../crud/CrudForm';
import { userService as service } from '../../../services';
import { idUser } from '../../../utils/session';

const initElement = {
      email: '',
      pass: '',
      name: '',
      lastname: '',
      isClient: false,
      rol: { code: null, name: '' },
      enable: true,
      picture: null,
};

type FormAdministratorParms = {
      id: string;
};

export default function FormMyAccount() {
      const [objectform, setObjectform] = React.useState({ ...initElement });
      const [idRow, setIdrow] = useState(idUser());

      const estructureform = [
            {
                  type: 'input',
                  name: 'email',
                  readonly: idRow > 0 ? true : false,
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'pass',
                  col: 4,
                  inputtype: 'password',
            },
            {
                  type: 'input',
                  name: 'name',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'lastname',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'phone',
                  col: 4,
            },
            {
                  type: 'image',
                  name: 'picture',
                  col: 3,
            },
      ];

      const recoveryElement = function (objectedit, element) {
            objectedit['email'] = element.email ? element.email : '';
            objectedit['name'] = element.name ? element.name : '';
            objectedit['lastname'] = element.lastname ? element.lastname : '';
            objectedit['rol'] = { code: element.rol && element.rol.code ? element.rol.code : null, name: element.rol && element.rol.name ? element.rol.name : null };
            objectedit['laundry'] = { id: element.laundry && element.laundry.id ? element.laundry.id : null, name: element.laundry && element.laundry.name ? element.laundry.name : null };
            objectedit['picture'] = element.picture ? element.picture : '';

            return objectedit;
      };

      const isValid = function (object) {
            if (!object['name']) {
                  return 'Debe indicar el nombre';
            }
            if (!object['email']) {
                  return 'Debe indicar el email';
            }
            if (!object['rol'] || !object['rol']['code'] || !object['rol']['code'] == null) {
                  return 'Debe indicar el rol';
            }

            return null;
      };

      return (
            <>
                  <CrudForm
                        objectform={{ ...initElement }}
                        estructureform={estructureform}
                        i18n={'myaccount'}
                        urledit={'/administrator/edit/'}
                        urlCancel={'/administrator'}
                        service={service}
                        recoveryElement={recoveryElement}
                        valid={isValid}
                  />
            </>
      );
}
