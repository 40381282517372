import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';
import { Card } from '@mui/material';
import styled from '@emotion/styled';

export default function HeaderPublic(props) {
    const { t, i18n } = useTranslation();

    return (
        <Card {...props}>
            <a href={process.env.REACT_APP_PROTECMEDIA} style={{display: "block", width: "100%", textAlign:"center"}}>
                <ImageLogo src="/logoheader.jpg" /> 
            </a>
        </Card>
      );

}

const ImageLogo = styled.img`
    width: 50%
`;