import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import {userService as service, suscriptionTypeService } from "../../../services"
import { useTranslation } from 'react-i18next';
import CrudForm from '../../crud/CrudForm';

const initElement = {
    email:"",
    name:"",
    lastname:"",
    picture:"",
    street: "",
    phone: "",
    number: "",
    door: "",
    floor: "",
    stairs: "",
    city: "",
    state: "",
    country:"España",
    postalcode:"",
    isClient:true,
    empresa:"",
    cargo:"",
    actividadEmpresa:"",
    rol:{code:"CLIENT",name:"CLIENT"},
    enable: true,
    pass:"",
    susUser:{
      suscription: {id:null,name:""},
      finishSuscription: null,
    }

  };

type FormClientParms = {
  id: string;
};
  

export default function FormClient() {
  let { id } = useParams<FormClientParms>();
  const [idRow, setIdrow] = useState(id && id != "new" ?Number(id):0);
  const { t, i18n } = useTranslation();



  const estructureform =  [
    {
      type:"input",
      name: "email",
      inputtype:"email",
      col: 4,
      readonly:idRow != 0
     },
     {
       type:"input",
       name: "name",
       col: 4
     },
     {
       type:"input",
       name: "lastname",
       col: 4
     },
     {
       type:"input",
       name: "street",
       col: 4
     },
     {
      type:"input",
      name: "number",
      col: 2
     },
     {
        type:"input",
        name: "door",
        col: 2
     },
     {
        type:"input",
        name: "floor",
        col: 2
     },
     {
        type:"input",
        name: "stairs",
        col: 2
     },
      {
         type:"input",
         name: "city",
         col: 4
      },
      {
         type:"input",
         name: "state",
         col: 4
      },
      {
         type:"input",
         name: "country",
         col: 4
      },
      {
         type:"input",
         name: "postalcode",
         col: 4
      },
      {
         type:"input",
         name: "empresa",
         col: 4
      },
      {
         type:"input",
         name: "cargo",
         col: 4
      },
      {
         type:"input",
         name: "actividadEmpresa",
         col: 4
      },
      {
         type:"checkbox",
         name: "enable",
         col: 2
      },
      {
         type:"input",
         name: "pass",
         inputtype: "password",
         col: 4
      },
      {
         type:"title",
         name: "titlesus",
         col: 12
      },
      {
        type: "autocomplete",
        name:"susUser.suscription.id",
        service: suscriptionTypeService.combo ,
        col: 6
      },
      {
         type:"input",
         name: "susUser.finishSuscription",
         inputtype:"date",
         col: 6
      },
    
    ];



  const recoveryElement = function(objectedit,element){

    objectedit = {...objectedit, ...element};

    if(objectedit.susUser == null){
      objectedit.susUser = {
        suscription: {id:null,name:""},
        finishSuscription: null,
      }
    }
   
    return objectedit;
  }




  const isValid = function(object){
  
    if(!object['name']){
      return t("error.name");
    }

      return null;
  }


  return (
    <CrudForm
        objectform = {{...initElement}}
        estructureform = {estructureform}
        i18n={"users"}
        urledit={"/users/edit/"}
        urlCancel={"/users"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
      />

  );
}
