import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import Copyright from '../../commons/Copyright';
import styled from "styled-components";
import { height } from '@mui/system';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { paymentPublicService as service} from '../../../services';
import { useHistory, useParams } from "react-router-dom";
import { Container } from '@mui/material';
import HeaderPublic from '../../commons/HeaderPublic';
import Form from '../../form/Form';

const initElement = {
    user:{id:null,name:""},
    suscriptionType: {id:null,name:""},
    createDate: null,
    state: null,
    prize: null,
    prizeNum: 0.0,
    needInvoice:false,
    nameInvoice: null,
    streetInvoice: null,
    numberInvoice: null,
    floorInvoice: null,
    townInvoice: null,
    stateInvoice: null,
    postalCodeInvoice: null,
    countryInvoice: null,
    taxIdentificationNumberInvoice: null,
    emailSendInvoice: null,
  
    };


export default function BillData(props) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const error = urlParams.get("error");

    let { id } = useParams<any>();
    const [idRow, setIdrow] = useState(id?Number(id):0);
    const { t, i18n } = useTranslation();
    const [data,setData] = useState({...initElement, ["suscriptionType"]:{id:idRow,name:""}})
    const [haveError,setHaveError] = useState(false)
    const [load,setLoad] = useState(false)
    const [mess,setMess] = useState(error && error == "1" ? "Error al realizar el pago, vuelva a intentarlo":"")
    let history = useHistory()




    const estructureform =  [
        {
         type:"checkbox",
         name: "needInvoice",
         col: 4
        },
        {
          type:"title",
          name:"datosfactura",
          col:12
        },
        {
          type:"input",
          name: "nameInvoice",
          col: 4
        },
        {
          type:"input",
          name: "emailSendInvoice",
          col: 4
        },
        {
          type:"input",
          name: "taxIdentificationNumberInvoice",
          col: 4
        },
        {
          type:"input",
          name: "streetInvoice",
          col: 4
        },
        {
         type:"input",
         name: "numberInvoice",
         col: 2
        },
        {
           type:"input",
           name: "door",
           col: 2
        },
        {
           type:"input",
           name: "floorInvoice",
           col: 2
        },
         {
            type:"input",
            name: "townInvoice",
            col: 4
         },
         {
            type:"input",
            name: "stateInvoice",
            col: 4
         },
         {
            type:"input",
            name: "countryInvoice",
            col: 4
         },
         {
            type:"input",
            name: "postalCodeInvoice",
            col: 4
         },
         {
          type:"renderCheckBoxLink",
          name: "acceptpolicy",
          link: "https://www.sweetpress.com/politica-de-privacidad",
          col: 12,
         },
  ];



    const pay = (object) => {
        if(isValid(object)) {
            setLoad(true);
            service.form(idRow, object, (data,error)=>{
                    setLoad(false);
                    if (data){
                        window.location.href = data;
                    }
            });
        }
       
    }



    const isValid = (object) => {

        if(!object["acceptpolicy"]){
            setMess("Debe aceptar nuestra politica de privacidad");
            return false;
        } else {
            return true;
        }

    }

    const goBack = (object) => {
        history.goBack();
    }

    return (<Main>
            <Container>
            <HeaderPublic style={{margin: "1em 0"}}/>
            <CardLongin>
                <Form t={t} i18n={"orders"}  objectform={data} fields={estructureform}  searchFilter={pay} cleanFilter={goBack} labelsave={"Pagar"} labelcancel={"Volver"} reload={false} haveMoreActions={false} load={load}/>
                {mess && mess != "" && <p style={{background: "rgba(255,0,0,0.6)", padding: "1em", borderRadius:"6px", margin: "1em 0", textAlign: "center", color:"darkred", border:"1px solid darkred", width:"100%"}}>{mess}</p>}
                <Copyright />
            </CardLongin>
            </Container>
            </Main>
            
      );

}

const CardLongin = styled(Card)`
    padding: 1em;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
`;


const Main = styled.div`
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
    width: 100%;
    height: 100vh;
    display: flex;
`;
