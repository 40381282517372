import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Document, Page, pdfjs } from 'react-pdf';
import useWindowDimensions from '../../utils/useWindowDimensions';
import "react-pdf/dist/esm/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewer = ({
  isMobile = false,
  pageNumber,
  numPages = 1,
  ...props
}) => {
  const { height, width } = useWindowDimensions();
  const pageSize = width < 500 ? height - (250 + (500 - width)) : height - 300;
  return (
    <Container>
      <Document {...props} externalLinkTarget="_blank">
        <PagesContainer>
          {isMobile ? (
            <Page key={pageNumber} pageNumber={pageNumber} height={pageSize} renderTextLayer={false}/>
          ) : pageNumber === 1 ? (
            <Page key={pageNumber} pageNumber={pageNumber} height={pageSize} renderTextLayer={false}/>
          ) : pageNumber === numPages ? (
            <Page key={pageNumber} pageNumber={pageNumber} height={pageSize} renderTextLayer={false} />
          ) : (
            <>
              <Page
                key={pageNumber}
                pageNumber={pageNumber}
                height={pageSize}
              />
              <Page
                key={pageNumber + 1}
                pageNumber={pageNumber + 1}
                height={pageSize}
              />
            </>
          )}
        </PagesContainer>
      </Document>
    </Container>
  );
};

const Container = styled.div`
  .react-pdf__message {
    color: #fff;
  }
`;

const PagesContainer = styled.div`
  display: flex;
`;
export default PdfViewer;
