import BaseService from './_base.service';

export const uploadService = {
    upload,
    uploadWithName,
    uploadAsync
};

function upload(image, callback) {
    return BaseService.postMultipart("/admin/upload/", image, callback, true)
}

function uploadWithName(image,name, callback) {
    return BaseService.postMultipartWithName("/admin/upload/", image, name, callback, true)
}

function uploadAsync(image, callback) {
    return BaseService.postMultipartAsync("/admin/upload/", image, callback, true)
}
