import CrudTable from "../../crud/CrudTable"
import {userService as service} from "../../../services"

const initFilter =  {
  name : null,
  email: null,
  phone:null,
  isClient:true
};

export default function Client() {

  const createEstructureFilter =  [
    {
      type:"input",
      name: "name",
      col: 4
     },
    {
      type:"input",
      name: "email",
      col: 4
     },
      {
        type:"input",
        name: "phone",
        col: 4
       },
 ];



  return (
    <CrudTable
          service={service}
          urledit={"/users/edit/"}
          urlnew={"/users/new/"}
          filter={initFilter}
          i18n={"users"}
          fieldstable={["picture","email","name","lastname","phone"]}
          estructureForm={createEstructureFilter}
          notexport={true} />

  );
}
