import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import CrudForm from "../../crud/CrudForm"
import {positionAdsService as service} from "../../../services"
import { useTranslation } from 'react-i18next';

const initElement = {
     code:"",
     name: ""
  };

  type FormAdministratorParms = {
    id: string;
  };
  
export default function FormPositionAds() {
  const [objectform, setObjectform] = React.useState({...initElement});
  let { id } = useParams<FormAdministratorParms>();
  const [idRow, setIdrow] = useState(id?Number(id):0);
  const { t, i18n } = useTranslation();


  const estructureform =  [
          {
           type:"input",
           name: "code",
           col: 4,
          },
          {
           type:"input",
           name: "name",
           col: 8
          },
    ];

  const recoveryElement = function(objectedit,element){


    objectedit['code']=element.code?element.code:"";
    objectedit['name']=element.name?element.name:"";

    return objectedit;
  }




  const isValid = function(object){
    if(!object['name']){
      return t("posicionads.error.name");
    }
    if(!object['code']){
      return t("posicionads.error.code");
    }

    return null;
}

  return (
    <><CrudForm
        objectform = {{...initElement}}
        estructureform = {estructureform}
        i18n={"posicionads"}
        urledit={"/posicionads/edit/"}
        urlCancel={"/posicionads"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
        fieldId={"code"}
      />
    </>

  );
}
