import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import './App.css';
import './language/i18n';
import { isNotLoginLogin, isClient } from './utils/session';
import LoginRoute from './components/route/LoginRoute';
import Login from './components/pages/login/Login';
import PrivateRoute from './components/route/PrivateRoute';
import Administrator from './components/pages/users/Administrator';
import Client from './components/pages/client/Client';
import FormClient from './components/pages/client/FormClient';
import FormAdministrator from './components/pages/users/FormAdministrator';
import FormMyAccount from './components/pages/users/FormMyAccount';
import Dashboard from './components/pages/dashboard/Dashboard';
import PosicionAds from './components/pages/positionads/PosicionAds';
import FormPositionAds from './components/pages/positionads/FormPositionAds';
import Ads from './components/pages/ads/Ads';
import FormAds from './components/pages/ads/FormAds';
import Visor from './components/pages/visor/Visor';
import Access from './components/pages/access/Access';
import Recovery from './components/pages/recovery/Recovery';
import Register from './components/pages/register/Register';
import Suscripciones from './components/pages/suscripciones/Suscripciones';
import SuscriptionType from './components/pages/suscriptiontype/SuscriptionType';
import FormSuscriptionType from './components/pages/suscriptiontype/FormSuscriptionType';
import Orders from './components/pages/orders/Ordes';
import FormOrders from './components/pages/orders/FormOrders';
import BillData from './components/pages/suscripciones/BillData';
import PagoConfirmado from './components/pages/suscripciones/PagoConfirmado';

export default function App() {
      const isLoggedNotIn = isNotLoginLogin();
      const isClientBoolean = isClient();

      return (
            <>
                  <Router>
                        <Switch>
                              <LoginRoute path="/login" component={Access} client={false} />
                              <PrivateRoute path="/administrator/new" component={FormAdministrator} />
                              <PrivateRoute path="/administrator/edit/:id" component={FormAdministrator} />
                              <PrivateRoute path="/administrator" component={Administrator} />
                              <PrivateRoute path="/dashboard" component={Dashboard} />
                              <PrivateRoute path="/users/new" component={FormClient} />
                              <PrivateRoute path="/users/edit/:id" component={FormClient} />
                              <PrivateRoute path="/users" component={Client} />
                              <PrivateRoute path="/myaccount" component={FormMyAccount} />
                              <PrivateRoute path="/posicionads/new" component={FormPositionAds} />
                              <PrivateRoute path="/posicionads/edit/:id" component={FormPositionAds} />
                              <PrivateRoute path="/posicionads" component={PosicionAds} />
                              <PrivateRoute path="/ads/new" component={FormAds} />
                              <PrivateRoute path="/ads/edit/:id" component={FormAds} />
                              <PrivateRoute path="/ads" component={Ads} />
                              <PrivateRoute path="/suscriptiontype/new" component={FormSuscriptionType} />
                              <PrivateRoute path="/suscriptiontype/edit/:id" component={FormSuscriptionType} />
                              <PrivateRoute path="/suscriptiontype" component={SuscriptionType} />
                              <Route path="/visorpdf" component={Visor} />
                              <LoginRoute path="/acceso" component={Access} client={true} />
                              <Route path="/recovery/:client" component={Recovery} />
                              <Route path="/recovery" component={Recovery} />
                              <Route path="/registro" component={Register} />
                              <Route path="/suscripciones/data/:id" component={BillData} />
                              <Route path="/suscripciones" component={Suscripciones} />
                              <Route path="/pedidoconfirmado" component={PagoConfirmado} />

                              <PrivateRoute path="/orders/edit/:id" component={FormOrders} />
                              <PrivateRoute path="/orders" component={Orders} />

                              {isLoggedNotIn ? <LoginRoute path="/" component={Access} client={false} /> : isClientBoolean ? <Route path="/acceso" component={Access} /> : <PrivateRoute path="/" component={Dashboard} />}
                        </Switch>
                  </Router>
            </>
      );
}
