import BaseService from './_base.service';

const URL = "/payment";

export const paymentPublicService = {
    form
};

function form(id,body,callback) {
    return BaseService.post(URL+"/form/"+id,body, callback)
}

