import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import Copyright from '../../commons/Copyright';
import styled from "styled-components";
import { height } from '@mui/system';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { userService as service, userService } from '../../../services';
import { useHistory } from "react-router-dom";
import { Container } from '@mui/material';
import HeaderPublic from '../../commons/HeaderPublic';

const loginData = {
    email:"",
    pass: ""
}

export default function Access(props) {
    const { t, i18n } = useTranslation();
    const [data,setData] = useState({...loginData})
    const [haveError,setHaveError] = useState(false)
    let history = useHistory()

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const redirect = urlParams.get("redirect");
    const idSus = urlParams.get("suscription");
    

    const onChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        setData(currentValueform => ({...currentValueform, [name]: value }))
    }

    const onSubmit = (evt) => {
        evt.preventDefault();
        userService.login(data, (data, error) => {
            if (data) {
                localStorage.setItem('token', data.token);
                localStorage.setItem('user', JSON.stringify(data.user));
                if (props.client || data.user.isClient) {
                    if (idSus != null){
                        window.location.href = "/suscripciones/data/"+idSus+(redirect? "?redirect="+encodeURI(redirect):"")
                    } else {
                        var url = process.env.REACT_APP_PROTECMEDIA+"/user-portlet/refreshuserentitlements?"+(redirect? "redirect="+encodeURI(redirect)+"&":"")+"ssodata="+data.user.ssodata;
                        console.log(url);
                        window.location.href=url;
                    }
                    
                } else {
                  history.push("/dashboard");
                }
               
             } else {
               setHaveError(true);
             }
        });
        return false;
    } 

    return (<Main>
            <Container>
            <HeaderPublic style={{margin: "1em 0"}}/>
            <CardLongin>
                <form method='post' onSubmit={onSubmit}>
                <DivForm>
                    <Typography variant="body2" color="textSecondary" align="center">Acceder</Typography>
                    <TextFieldForm value={data.email} id="email" name="email" required variant="standard" label={t('login.email')} onChange={onChange} type="email"/><br/>
                    <TextFieldForm value={data.pass} id="email" name="pass" required type="password" variant="standard" label={t('login.pass')}  onChange={onChange}/><br/>
                    {haveError && <Typography variant="body2" color="error" align="center">{t('login.error')}</Typography> }
                    <ButtonForm variant="contained" color="primary" type="submit">{t('login.login')}</ButtonForm><br/>
                    <Link href="/recovery/client" underline="hover" color="primary">{t('login.recovery')}</Link><br/>
                    {props.client && <><Link href={"/registro?"+(redirect? "redirect="+encodeURI(redirect)+"&":"")+(idSus? "suscription="+idSus:"")} underline="hover" color="primary">Registrate</Link><br/></>}
                </DivForm>
                </form>
                <Copyright />
            </CardLongin>
            </Container>
            </Main>
            
      );

}

const CardLongin = styled(Card)`
    padding: 1em;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const TextFieldForm = styled(TextField)`
    margin-bottom:20px;
`;
const ButtonForm = styled(Button)`
    margin-top: 20px;
    margin-bottom: 20px;
`;

const Main = styled.div`
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
    width: 100%;
    height: 100vh;
    display: flex;
`;

const ImgLogo = styled.img`
    width: 200px;
    max-width: 40%;
`;

const DivForm =  styled.div`
    display: flex;
    flex-direction:column;
    width: 400px;
    max-width: 90%;
    row-gap: 10px;
`;